import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PrivateRoutes from "./Components/PrivateRoute/PrivateRoute";
import DashboardPage from "./Pages/DashboardPage/DashboardPage";
import ContentNewsFeedPage from "./Pages/ContentNewsfeed/ContentNewsFeed";
import AdminDashboardPage from "./Admin/Pages/AdminDashboardPage";
import ProfilePage from "./Pages/Profile/Profile";
import ChangePasswordFromMail from "./Pages/ChangePasswordFromMail/ChangePasswordFromMail";
import SocialListeningPage from "./Pages/SocialListeningPage/SocialListeningPage";
import LoginPage from "./Pages/Login/Login";
import AdminRoutes from "./Components/PrivateRoute/AdminRoutes";
import UserManagementPage from "./Pages/UserManagement/UserManagement";
import CustomerAdminSetting from "./Pages/CustomerAdminSetting/CustomerAdminSetting";
import { SnackbarProvider } from "notistack";
import Toast from "./Components/Toast";
import { createTheme, ThemeProvider, CssBaseline } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChangePassword, getSignInUser } from "./store/actions/AuthAction";
import { LoadLogoAndBanner } from "./store/actions/SettingActions";
import { useSelector } from "react-redux";

/* Setting up or registring axios config */
import "./axiosConfig";
import ProfileListingPage from "./Pages/ProfileListingPage/ProfileListingPage";
import ProfileOverview from "./Pages/ProfileListingPage/ProfileOverview";
import ComparisonPage from "./Pages/ComparisionPage/ComparisonPage";
import Help from "./Pages/Help/Help";
import HelpPageFaq from "./Pages/Help/HelpPageFaq";
import HelpPageDoucment from "./Pages/Help/HelpPageDoucment";
import HelpPageVideo from "./Pages/Help/HelpPageVideo";
import { ContactSupport } from "./Admin/Pages/ContactSupport/ContactSupport";
import UserActivity from "./Admin/Pages/UserActivity/UserActivity";
import { getSubDomain } from "Functions";

function App() {
  const dispatch = useDispatch();
  const { logoBannerDataLoaded } = useSelector((state) => state.settings);
  const { serverError } = useSelector((state) => state.socialMediaProfileListReducer);
  const { user } = useSelector((state) => state.auth);
  const theme = createTheme({
    props: {
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application!ßßßß
      },

      MuiOutlinedInputNotchedOutline: {
        notched: true,
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      whiteSpace: "nowrap !important",
    },
    palette: {
      common: {
        darkGreen: "#33918a",
        lightGreen: "red",
        lightBlack: "#323132",
      },
    },
  });

  useEffect(() => {
    const subDomain = getSubDomain();
    console.log({ subDomain });

    if (subDomain) {
      dispatch(LoadLogoAndBanner(subDomain));
    }
  }, []);

  useEffect(() => {
    if (serverError === true) {
      toast.error("check your internet connection");
    } else {
      console.log("app connected");
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getSignInUser(user.id));
    }
  }, []);

  return !getSubDomain() || logoBannerDataLoaded ? (
    <SnackbarProvider maxSnack={5}>
      <ThemeProvider theme={theme}>
        <Toast />
        <ToastContainer autoClose={3200} hideProgressBar />
        <Switch>
          <AdminRoutes exact path='/admin/dashboard' component={AdminDashboardPage} />
          <AdminRoutes exact path='/admin/user-activity' component={UserActivity} />
          <AdminRoutes exact path='/contact-support' component={ContactSupport} />
          <PrivateRoutes exact path='/brand-overview' component={ProfileOverview} />

          <PrivateRoutes exact path='/comparision' component={ProfileListingPage} />
          <PrivateRoutes exact path='/profile-comparison' component={ComparisonPage} />
          <AdminRoutes exact path='/profile' component={ProfilePage} />
          <AdminRoutes exact path='/user-management' component={UserManagementPage} />
          <AdminRoutes exact path='/customer-admin-setting' component={CustomerAdminSetting} />

          <PrivateRoutes exact path='/social-listening' component={SocialListeningPage} />
          <PrivateRoutes exact path='/content-newsfeed' component={ContentNewsFeedPage} />
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/change-password' component={ChangePassword} />
          <AdminRoutes exact path='/help' component={Help} />
          <Route exact path='/change-password-from-mail' component={ChangePasswordFromMail} />
          <PrivateRoutes exact path='/' component={DashboardPage} />
          <PrivateRoutes exact path='/help/faq' component={HelpPageFaq} />
          <PrivateRoutes exact path='/help/how-to-document' component={HelpPageDoucment} />
          <PrivateRoutes exact path='/help/videos' component={HelpPageVideo} />
        </Switch>
        <CssBaseline />
      </ThemeProvider>
    </SnackbarProvider>
  ) : (
    ""
  );
}

export default App;
